import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`PROJECTS`}</h2>
    <ProjectCard title="CHAT ZONE" link="https://github.com/dev5151/CHAT-ZONE.git" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This is an Android App that allows user to chat privately as well as in a public room.
    </ProjectCard>      
    <ProjectCard title="HEAD COUNT" link="https://play.google.com/store/apps/details?id=com.acmvit.headcount" bg="linear-gradient(to right, #078c26 0%, #22d64c 100%)" mdxType="ProjectCard">
This is a Revolutionary Android App that has provided with a new way of taking attendance in meeting, class rooms or in any event and even duplicacy is not possible.
    </ProjectCard>      
    <ProjectCard title="ROBOCARE" link="https://github.com/dev5151/ROBOCARE" bg="linear-gradient(to right, #1920e6 0%, #3b85fb 100%)" mdxType="ProjectCard">
An App that monitors over a sick (specifically Paralysis Patients) person's heart rate, body temp and alerts the Family Members in need .
    </ProjectCard>      
    <ProjectCard title="More Projects" link="https://github.com/dev5151/" bg="linear-gradient(to right, #5e078c 0%, #f024d8 100%)" mdxType="ProjectCard">
Here are some of the other cool projects that i have worked on. Hope you like it .
    </ProjectCard>      

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      