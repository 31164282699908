import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src="ProfilePic.jpeg" alt="ProfilePic" height="200" width="200" algin="middle" />
    <h1>{`Hi, I'm Devansh Mehta`}</h1>
    <p>{`I'm an Android Developer and a Data Science Enthusiast`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      