import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`A Foodie by nature and  a developer by passion, a wannapreneur, tech enthusiast, `}<a parentName="p" {...{
        "href": "https://github.com/dev5151"
      }}>{`open source contributor`}</a>{`, `}<a parentName="p" {...{
        "href": "https://medium.com/@devaansh51"
      }}>{`tech blogger`}</a>{` and a mentor for those who want to learn exciting new Technologies.`}</p>
    <p>{`I started off with Android Development, did some DataScience and currently i am thinking of exploring DevOps.`}</p>
    <p>{`I love attending hackathons and hacking solutions that can contribute to development of society. I am always open for new Projects Ideas.`}</p>
    <p>{`I love meeting new people and learning from their experiences.`}</p>
    <h4>{`"Be weird, Be you"`}</h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      