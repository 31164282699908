import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Connect wih me on other platforms: `}</p>
    <a href="https://www.linkedin.com/in/devansh-mehta-181381167/" hspace="20">
      <img border="0" alt="LinkedIn" src="linkedin.png" width="100" height="100" style={{
        "margin": "0px 20px"
      }} />
    </a>
    <space>  </space>
    <a href="https://github.com/dev5151">
      <img border="0" alt="Github" src="github.png" width="100" height="100" style={{
        "margin": "0px 20px"
      }} />
    </a>
    <a href="https://www.instagram.com/dev51mehta/">
      <img border="0" alt="Insta" src="instagram.png" width="100" height="100" style={{
        "margin": "0px 20px"
      }} />
    </a>
    <a href="https://medium.com/@devaansh51">
      <img border="0" alt="Meduim" src="medium.png" width="100" height="100" style={{
        "margin": "0px 20px"
      }} />
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      